import React from 'react'
import * as styles from './Item.module.scss'
import cn from 'classnames'

import { Title, SubTitle } from 'ui'

const Item = ({ Icon, title, text, iconwrapClass, iconInnerwrapClass }) => {
  return (
    <div className={styles.item}>
      <div className="col-xxl-3 col-lg-4 col-md-4 col-sm-12">
        <div className={cn(styles.iconWrap, iconwrapClass)}>
          <div className={cn(styles.iconWrap__inner, iconInnerwrapClass)}>
            <Icon className={styles.icon} />
          </div>
        </div>
      </div>
      <div
        className={cn(
          'col-xxl-8 col-lg-8 col-md-8 col-sm-12',
          styles.item__text,
        )}>
        <Title
          variant="small"
          text={title}
          className={styles.item__text__title}
        />
        <SubTitle
          variant="small"
          className={styles.item__text__subtitle}
          text={text}
        />
      </div>
    </div>
  )
}
export default Item
