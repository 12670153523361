import React from 'react'
const Icon1 = props => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_1330_10477)">
        <path
          d="M2.00101 48C2.00101 48 1.99921 39.5576 2.00022 37C2.00123 34.4424 5 30 10 30C15 30 21 30 26 30C31 30 33.9862 34.7243 34.0002 37C34.0143 39.2758 34.001 48 34.001 48"
          stroke="#FF6A3C"
          strokeWidth="3"
        />
        <circle cx="18" cy="12" r="10" stroke="#FF6A3C" strokeWidth="3" />
        <path
          d="M45.1159 23.4968L39 30L32.8841 23.4968C31.6634 22.1988 31.7126 20.2029 32.9957 18.9626C34.4284 17.5776 36.7884 17.7039 38.0542 19.2334L39 20.3763L39.9458 19.2334C41.2116 17.7039 43.5716 17.5776 45.0043 18.9626C46.2874 20.2029 46.3366 22.1988 45.1159 23.4968Z"
          fill="#FF6A3C"
        />
      </g>
      <defs>
        <clipPath id="clip0_1330_10477">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Icon1
