// extracted by mini-css-extract-plugin
export var animate1 = "TextWithCubes-module--animate1--jzCG-";
export var animate2 = "TextWithCubes-module--animate2--a9xwn";
export var animate3 = "TextWithCubes-module--animate3--LjR+8";
export var anime1 = "TextWithCubes-module--anime1--ev3qV";
export var anime2 = "TextWithCubes-module--anime2--rdo0t";
export var anime3 = "TextWithCubes-module--anime3--KrPMK";
export var content = "TextWithCubes-module--content--V6UrU";
export var iconBottom = "TextWithCubes-module--iconBottom--J4lKa";
export var iconMiddle = "TextWithCubes-module--iconMiddle--UFBkn";
export var iconTop = "TextWithCubes-module--iconTop--AAP2R";