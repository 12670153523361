import * as styles from './TextWithCubes.module.scss'
import cn from 'classnames'
import React from 'react'

import Icon1 from 'components/Icons/squaredIcons/Icon1'
import Icon2 from 'components/Icons/squaredIcons/Icon2'
import Icon3 from 'components/Icons/squaredIcons/Icon3'

import Item from './Item'

const TextWithCubes = ({
  title_1,
  sub_title_1,
  title_2,
  sub_title_2,
  title_3,
  sub_title_3,
}) => {
  return (
    <div className={cn('row', styles.content)}>
      <div className="col-12">
        <Item
          iconwrapClass={styles.iconTop}
          iconInnerwrapClass={styles.animate1}
          Icon={Icon1}
          title={title_1}
          text={sub_title_1}
        />
        <Item
          iconwrapClass={styles.iconMiddle}
          iconInnerwrapClass={styles.animate2}
          Icon={Icon2}
          title={title_2}
          text={sub_title_2}
        />
        <Item
          iconwrapClass={styles.iconBottom}
          iconInnerwrapClass={styles.animate3}
          Icon={Icon3}
          title={title_3}
          text={sub_title_3}
        />
      </div>
    </div>
  )
}

export default TextWithCubes
