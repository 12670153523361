import React, { forwardRef } from 'react'
import * as styles from './Square.module.scss'
import cn from 'classnames'

const Square = forwardRef(({ Icon, className }, ref) => {
  return (
    <div className={cn(styles.square, className)} ref={ref}>
      {Icon && <Icon />}
    </div>
  )
})

export default Square
