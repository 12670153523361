import React from 'react'
const Icon2 = props => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_1330_10484)">
        <path
          d="M39.5571 6.39271L40.154 6.15396L40.3927 5.55709L41 4.03887L41.6073 5.55709L41.846 6.15396L42.4429 6.39271L43.9611 7L42.4429 7.60729L41.846 7.84604L41.6073 8.44291L41 9.96113L40.3927 8.44291L40.154 7.84604L39.5571 7.60729L38.0389 7L39.5571 6.39271Z"
          stroke="#FF6A3C"
          strokeWidth="3"
        />
        <path
          d="M38.5571 31.3927L39.154 31.154L39.3927 30.5571L40 29.0389L40.6073 30.5571L40.846 31.154L41.4429 31.3927L42.9611 32L41.4429 32.6073L40.846 32.846L40.6073 33.4429L40 34.9611L39.3927 33.4429L39.154 32.846L38.5571 32.6073L37.0389 32L38.5571 31.3927Z"
          stroke="#FF6A3C"
          strokeWidth="3"
        />
        <path
          d="M14.5571 7.39271L15.154 7.15396L15.3927 6.55709L16 5.03887L16.6073 6.55709L16.846 7.15396L17.4429 7.39271L18.9611 8L17.4429 8.60729L16.846 8.84604L16.6073 9.44291L16 10.9611L15.3927 9.44291L15.154 8.84604L14.5571 8.60729L13.0389 8L14.5571 7.39271Z"
          stroke="#FF6A3C"
          strokeWidth="3"
        />
        <path
          d="M19.997 22C14.1736 27.8226 6.83211 35.1637 3.99832 38C-0.997288 43 5.28871 48.7156 9.99832 44C12.6836 41.3113 20.1092 33.8862 25.9965 28M19.997 22C24.4395 17.5581 27.9984 14 27.9984 14L33.9984 20C33.9984 20 30.4345 23.5627 25.9965 28M19.997 22L25.9965 28"
          stroke="#FF6A3C"
          strokeWidth="3"
        />
      </g>
      <defs>
        <clipPath id="clip0_1330_10484">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Icon2
